#basic > div.grid.grid-cols-2.w-full > div:nth-child(1) > div:nth-child(1) > div > div.ant-col.ant-form-item-control > div > div > span {
    width: 90% !important;
}

.card-search {
    border-radius: 5px !important;
}

input[type="file"] {
  
    display: none;
}
.custom-file-upload {
    border: 1px dashed #ccc;
    display: inline-block;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 12px;
    width: 100%;

    transition: all ease .3s;
}
.custom-file-upload:hover {
    border: 1px dashed #5564f3;
    color: #5564f3;
}

#root > section > section > main > div > div:nth-child(1) > div.ant-card-body > div > div > div > div > div > div.ant-table-body > table > tbody > tr.ant-table-row.ant-table-row-level-0 > td:nth-child(1) > button.ant-btn-primary:disabled {
    cursor: not-allowed !important;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    box-shadow: none !important;
}

.home-owner .ant-select-selector {
    height: 50.28px !important;
    align-items: center !important;
    font-size: 18px !important;
}

