#root > section > section > main > div > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > div.ant-tabs-tab.ant-tabs-tab-active {
   background-color: #4d8e03 !important;
   color: white !important;

   transition: all ease .3s !important;
}

#root > section > section > main > div > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > div.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
    transition: all ease .3s !important;
}

.tabs-transac .ant-tabs-tab {
    color: rgba(95, 95, 95, 0.579) !important;
}
 
