.card-container:hover > .card:not(:hover){
    transition: all ease 0.3s;
    transform: scale(.9);
    filter: brightness(0.5)  blur(2px);
}
.card:hover {
    transform: scale(1.1) translateZ(0);
    transition: all ease 0.3s;
}

.card {
    border-radius: 10px;
}

.card-container .card {
    transition: all ease 0.3s;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  