.pending-card {
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 6px rgb(0 0 0 / 0.2);
    transition:  all ease 0.3s;
}

.pending-card::before {
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    background-color: #FF0000;
    position: absolute;
    transition:  all ease 0.3s;
    z-index: 1;
}

/* .pending-card:hover::before {
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #FF0000;
    position: absolute;
    transition:  all ease 0.3s;
    z-index: 1;
} */
/* 
.pending-card:hover .pending-count {
    background-color: #ff2727;
    transition:  all ease 0.3s;
    z-index: 1;
} */

.processed-card {
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 6px rgb(0 0 0 / 0.2);
    transition:  all ease 0.3s;
    z-index: 1;
}

.processed-card::before {
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    background-color: #ebd339;
    position: absolute;
    transition:  all ease 0.3s;
    z-index: 1;
}

/* .processed-card:hover::before {
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ebd339;
    transition:  all ease 0.3s;
    position: absolute;
    z-index: 1;
} */

/* .processed-card:hover .processed-count {
    background-color: #f2dd59;
    transition:  all ease 0.3s;
    z-index: 1;
} */


.resolved-card {
    position: relative;
    overflow: hidden;
    transition:  all ease 0.3s;
    box-shadow: 0 2px 6px rgb(0 0 0 / 0.2);
}

.resolved-card::before {
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    background-color: #03cd03;
    transition:  all ease 0.3s;
    position: absolute;
}

/* 
.resolved-card:hover::before {
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #03cd03;
    transition:  all ease 0.3s;
    position: absolute;
    z-index: 1;
} */

/* .resolved-card:hover .resolved-count {
    background-color: #05ed05;
    transition:  all ease 0.3s;
    z-index: 1;
}
 */
