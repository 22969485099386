@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;800&display=swap");
 */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;600;700;800&display=swap");
* {
	font-family: "Poppins", sans-serif;
	padding: 0;
	margin: 0;
}

.antd {
	font-family: "Poppins", sans-serif;
}

.App {
	padding: 0;
	margin: 0;
}

.index {
	margin: 0;
	padding: 0;
}
