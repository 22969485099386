#root > section > section > main > div > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > div.ant-tabs-tab.ant-tabs-tab-active {
    background-color: #4d8e03 !important;
    color: white !important;
    transition: all ease .3s !important;

 }
 
 #root > section > section > main > div > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > div.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
     color: white !important;
     transition: all ease .3s !important;
 }
 
.tabs-billing .ant-tabs-tab {
     color: rgba(95, 95, 95, 0.579) !important;
     position: relative !important;
}
  
.ant-tabs-nav {
    margin-bottom: 0px !important;
}

.socmeds {
    transition: all ease 0.5s;
    cursor: pointer;
}

.socmeds:hover {
    transition: all ease 0.5s;
    transform: translateY(-10px);
}

.ant-table-thead .ant-table-cell {
    background-color: #F5F7FB !important;
    padding: 10px !important;
    text-align: center;
} 

.table-charge .ant-table-cell:empty:after {
    content: '';
}

