.active-link {
	color: white;
	/* background-color: brown; */
	border-bottom-style: solid;
	border-bottom-color: white;
}
.not-home-active {
	color: #57833c;
	/* background-color: green; */
	border-bottom-style: solid;
	border-bottom-color: #57833c;
}

.active-link2 {
	background-color: #4d8e03;
}

.not-home-active2 {
	background-color: #c5c5c5;
}
