    

.site-layout .site-layout-header {
    background: #1890ff;
}

.main-header .ant-layout-header {
    background: transparent;
    height: 8rem;
}

.logo-container {
    width:  90%;
    margin: auto;
}

.button-right {
    float: right;
    margin-right: 20px;
}

.current-user-container {
    align-self: center;
}
  
.current-user {
    margin-bottom: 0px;
}
  
.ant-popover-content {
    border-radius: 2px;
}
  
.ant-popover-arrow {
    width: 0px !important;
}
  
.ant-avatar {
    cursor: pointer;
    background-color: white;
}
  
.ant-popover-inner {
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 30%), 0px 2px 6px 2px rgb(60 64 67 / 15%);
    height: 100%;
    width: 100%;
}

.edit-btn {
    border-radius: 3px !important;
    border: 2px solid #e2e2e2 !important;
    width: 100px !important;
    height: 35px !important;
    font-size: 0.8rem;
    font-weight: 500;
}

.ant-table-cell:nth-child(1), .ant-table-cell:last-child {
    border-radius: 0px 3px 3px 0px !important;
}

.ant-table-cell {
    border: 0px !important;
}

.ant-popover-inner {
    min-width: 352px !important;
    border-radius: 2px !important;
}

.ant-drawer-body {
    padding: 0px !important;
}
