.loc .ant-select-selector {
    height: 50.28px !important;
    align-items: center;
    font-size: 18px;
}

.ant-picker {
    height: 50.28px !important;
    align-items: center;
    font-size: 18px;
    width: 100%;
}

#basic > div:nth-child(1) > div:nth-child(2) > div:nth-child(3) > div > div.ant-col.ant-form-item-control > div > div > div > div > input {
    font-size: 18px;
}

#basic_phoneNumber {
    height: 50.28px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#back-to-login:hover {
    background-color: rgb(238, 238, 238);
    transition: all ease 0.3s;
    transform: scale(1.2);
    cursor: pointer;
}

.ant-select-selection-search-input {
    height: 100% !important;
    width: 100% !important;
    align-items: center !important;
    margin: auto !important;
}

.bg-main {
    background: linear-gradient(107.63deg, #E5F4FF 6.95%, #FFFFFF 97.99%);
}

.card-sub {
    border-color: transparent !important;
    background: linear-gradient(201.7deg, #E0F2FF 3.69%, rgba(242, 249, 255, 0) 97.37%) !important;
    border-radius: 10px !important;
    min-height: 834px !important;
    min-width: 531px !important;
}   

.card-sub-one {
    border-color: transparent !important;
    min-height: 694px !important;
    min-width: 432px !important;
    border-radius: 10px !important;
    background-color: #F3F8FC !important;
}

.card-sub-two {
    border-color: transparent !important;
    min-height: 694px !important;
    min-width: 432px !important;
    border-radius: 10px !important;
    background: #506CDF !important;
}

.card-sub-two:hover {
    border-color: transparent !important;
    min-height: 694px !important;
    min-width: 432px !important;
    border-radius: 10px !important;

    background: #506CDF !important;
    box-shadow: 0px 0px 16.5564px #506CDF, 0px 0px 9.4608px rgba(80, 108, 223, 0.5) !important;    
}

.steps-content {
    min-height: 200px;
 
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
  }
  
  .steps-action {
    margin-top: 24px;
  }

  .steps-sub {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    height: 100px;
    width: 100%;
    background: black;
  }

  .assoc-name-title {
    color: #568634;
    font-size: 16px;
    font-weight: 500;
  }
