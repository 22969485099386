.ant-carousel .slick-dots li button {
    margin-top: 20px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
}
.ant-carousel .slick-dots li.slick-active button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: #1171bb;
}
.ant-carousel .slick-dots li button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: black;
}

.delete-overlay:hover {
    background-color: black !important;
    opacity: .6;
    cursor: pointer;
}

#root > section > section > main > div > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > div.ant-tabs-tab.ant-tabs-tab-active {
    background-color: #4d8e03 !important;
    color: white !important;
 
    transition: all ease .3s !important;
}
 
#root > section > section > main > div > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > div.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
     color: white !important;
     transition: all ease .3s !important;
}
 
.tabs-help .ant-tabs-tab {
     color: rgba(95, 95, 95, 0.579) !important;
}
