@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Imperial+Script&family=Poppins:wght@300;400;500;600;700&display=swap');

@layer components {
    .poppins {
      font-family: 'Poppins', sans-serif;
    }
    .btn-paid {
      background-color: #4a7c47bd !important;
    }
    .btn-pending {
      background-color: rgba(185, 185, 4, 0.782) !important;
    }
    .btn-cancelled {
      background-color: #fa4239 !important;
    }
    .input-gray  {
      background-color: #F2F2F2 !important;
      border: 2px solid #D7D7D7 !important;
    }
    .input-text-gray .ant-input {
      background-color: #F2F2F2 !important;
    }
    .dropdown-action  {
     width: 50px !important;
      background: #F2F2F2 !important;
      box-shadow: 0px 32px 13px rgba(0, 0, 0, 0.01), 0px 18px 11px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.09), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
      border-radius: 10px !important;
    }
    .export-btn {
      background: #4d8e03 !important;
      color: white !important;
    }
    .subs-bg {
      background: linear-gradient(107.63deg, #E5F4FF 6.95%, #FFFFFF 97.99%);
    }
    .review-bg {
      background: linear-gradient(201.7deg, #E0F2FF 3.69%, rgba(242, 249, 255, 0) 97.37%);
      border-radius: 10px;
    }

    .form-title {
      @apply font-[500] text-[14px] mb-0 md:text-[18px] text-[#1b1b1b]
    }
}  

#root > section > section > main > div.text-4xl.absolute.right-\[50px\].bottom-\[50px\] > button {
  z-index: 999 !important; 
}

#root > section > section > main > div.text-4xl.absolute.right-\[50px\].bottom-\[50px\] > button > span {
  display: flex !important;
}

.message-hover {
  width: 150px !important;
  border-radius: 50px;
  color: rgb(96 165 250);
  border: 1px solid rgb(96 165 250);
}

.message-hover:focus {
  border-radius: 50px;
}

* {
  font-family: 'Poppins', sans-serif;
  margin: 0px;
  padding: 0px;
  overflow-anchor: inherit !important;
}

.master-table .ant-table-cell:empty:after {
  content: '';
}

#daily_reports_widget, #homeowners_reports_widget {
  background-color: #DBECFD;
}
#overall_transactions {
  background-color: #D2FFDB;
}
#daily_reports_widget::before, #homeowners_reports_widget::before {
  content: "";
  background-color: rgba(7, 75, 139, 0.658);
  width: 20px;
  border-radius: 10px 0px 0px 10px ;
}

#overall_transactions::before {
  content: "";
  background-color: #84a089;
  width: 20px;
  border-radius: 10px 0px 0px 10px ;
}

.ant-input[disabled] {
  color: black !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: black !important;
}

.menu-items {
  gap: 100px !important;
  margin-top: 0px !important;
}

.menu-items .ant-menu-item-selected {
  background-color: #4d8e03 !important;
  height:50px !important;
  width: 95% !important;
  margin: auto;
  color: white !important;
  border-radius: 5px;

  max-width: 280px;
  transition-timing-function: ease-in !important;
  transition: all 0.4s !important;
}

.menu-items .ant-menu-item:active {
  background-color: #4d8e03 !important;
  height:50px !important;
  width: 95% !important;
  margin: auto;
  color: white !important;
  border-radius: 5px;


  transition-timing-function: ease-in !important;
  transition: all 0.4s !important;
}

.menu-items .ant-menu-item {
  background-color: transparent;
  border-radius: 5px;

  margin: auto;
  transition-timing-function: ease-in !important;
  transition: all 0.4s !important;
}

.ant-tabs-tab {
  color: rgba(95, 95, 95, 0.579) !important;
  position: relative !important;
}

.ant-tabs-nav {
 margin-bottom: 0px !important;
}

.menu-items .ant-menu-item-selected .menu-item {
  color: white !important;
}

.ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
  border: 0 !important;
}

.ant-table-thead .ant-table-cell {
  background-color: #F5F5F5;
  padding: 7px;
  text-align: center;
}

.card-dash .ant-card-body {
  width: 100%;
}

.menu-dash .ant-menu {
  background-color: transparent !important;
}

.menu-dash .ant-layout-header {
  height: 100% !important;
  padding-bottom: 10px;
}

.menu-dash .ant-menu li {
  font-weight: 600;
}

.menu-dash .ant-menu li:nth-child(1) {
  padding-left: 0px !important;
}

.menu-dash .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#basic > div > div.grid.grid-cols-2 > div:nth-child(2) > div.ant-form-item.ant-form-item-has-success > div > div.ant-col.ant-form-item-control > div > div > div > div > div.ant-input-number.ant-input-number-in-form-item.ant-input-number-status-success > div.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-table-cell:empty:after {
  content: '-';
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #4d8e03;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #63b605; 
  
}

.ant-btn-loading {
  color: white !important;
}

#asd > div.ant-popover-inner-content > div > form > div.flex.justify-between > div > div > div > div > div > div > span > div.ant-upload-list.ant-upload-list-picture > div > div > span.ant-upload-list-item-name {
  display: none !important;
}

.loading-modal .ant-modal-content {
  border-radius: 20px !important;
  align-items: center !important;
  display: flex ;
  justify-content: center;
  height: 250px;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

